
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ChatsActiveCallSettingsDevice from '@/components/pages/chats/activeCall/ChatsActiveCallSettingsDevice.vue'

export default defineComponent({
  components: {
    WhiteBlock,
    DetailsHero,
    ChatsActiveCallSettingsDevice,
    TmFormLine,
    InfoCard,
    TmCountry,
    ChatsSettingsPage,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Call settings' },
    ]
    const callerOptions = [
      {
        id: 'fr',
        name: '+33 06 8657 9014',
        label: 'Another label',
      },
      {
        id: 'us',
        name: '+1 36 1536 1707',
        label: 'USA Support',
      },
      {
        id: 'us',
        name: '+1 21 4436 9101',
        label: 'USA Support',
      },
    ]
    const caller = ref(callerOptions[0])
    const recordCallsOptions = [
      'All calls (inbound and outbound)',
      'Inbound calls',
      'Outbound calls',
    ]
    const recordCalls = ref(recordCallsOptions[0])
    const storeCallsOptions = [
      'Forever',
      '1 month',
      '3 months',
      '6 months',
      '1 year',
    ]
    const storeCalls = ref(storeCallsOptions[0])

    return {
      storeCalls,
      storeCallsOptions,
      recordCalls,
      recordCallsOptions,
      breadcrumbs,
      caller,
      callerOptions,
    }
  },
})
