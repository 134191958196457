
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
  },
  props: {
    white: {
      type: Boolean,
    },
  },
  setup() {
    const deviceList = ref([
      'Default - Headset Microphone',
      'Headset Microphone (Razer)',
    ])
    const volume = ref(2)
    const output = ref(deviceList.value[0])
    const input = ref(deviceList.value[0])

    return {
      deviceList,
      volume,
      output,
      input,
    }
  },
})
